<template>
  <div class="content">
    <PageHeader :title="$t('generic-str.menu.catalog')" />
    <div class="page-content container-fluid">
      <div class="contact-list block-el">
        <div v-if="fetched" class="container-fluid">
          <div class="row">
            <router-link
              v-for="integration in integrations"
              :key="integration.id"
              :to="`integrations/${integration.id}`"
              tag="div"
              class="col-md-6 col-lg-4 col-xxl-3"
              role="button"
            >
              <div class="card contact-item">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <img
                        :src="`img/integrations/${integration.logo}`"
                        alt="user"
                        class="max-w-100"
                      />
                    </div>
                    <div class="col-md-12 text-center">
                      <h5 class="card-title">{{ integration.name }}</h5>
                      <small class="text-muted d-block">
                        {{ integration.description }}
                      </small>
                      <h3>
                        <span
                          v-if="integration.pivot"
                          class="badge badge-success"
                        >
                          {{$tc('generic-str.menu.installed', 1)}}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
        <div v-else class="static qt-block-ui" style="padding: 120px" />
      </div>
    </div>
    <new-invoice-modal
      @sent="fetch(form.page)"
      id="new-invoice-modal"
    ></new-invoice-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import IntegrationService from '@/services/integration.service';
import PageHeader from '@/components/PageHeader.vue';
import NewInvoiceModal from '@/components/invoices/NewInvoiceModal.vue';

export default {
  name: 'IntegrationCatalog',
  components: {
    PageHeader,
    NewInvoiceModal,
  },
  data() {
    return {
      fetched: false,
      integrations: [],
      form: {
        page: 1,
      },
      pages: 1,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.fetched = false;
      this.form.page = page;
      IntegrationService.getIntegrations(this.form).then(
        (integrations) => {
          this.fetched = true;
          this.integrations = integrations;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
